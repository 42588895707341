import OnecloudOauthClient from '../../clients/oneCloud/oauth/OnecloudOauthClient';
import OnecloudRegistryRelationshipClient from '../../clients/oneCloud/registryRelationship/OnecloudRegistryRelationshipClient';
import { ExchangeV3Client } from '../../clients/shell/exchange';
import { LogoutClient } from '../../clients/shell/logout';
import { ProviderClient } from '../../clients/shell/provider';
import { AccountsClient } from '../../clients/stratus/accountmgtsvc';
import { AuthzClient } from '../../clients/stratus/authz';
import { PersonClient } from '../../clients/stratus/tntCommonResourceRegistry';
import UserMgtClient from '../../clients/stratus/usersMgt/UserMgtClient';
import { ClientInstantiateType, ClientsType } from './ClientsType';
import { GrantsClient } from '../../clients/stratus/grants';
import UserTenantDetailsClient from '../../clients/stratus/usersMgt/UserTenantDetailsClient';
import { OnecloudLogoutClient } from '../../clients/oneCloud/oauth/logout';

export default class ClientsInitializer {
  public static instantiate({
    applicationService,
    authProviderService
  }: ClientInstantiateType): ClientsType {
    const stack = applicationService.getAuthStack();
    const clientId = applicationService.getClientId();

    const clients = {
      shell: {
        providerClient: new ProviderClient({
          clientId,
          // TODO: Check if there's a better way to get this in the future
          baseUrl: window?.location?.origin
        }),
        logoutClient: new LogoutClient()
      },
      stratus: {
        authzClient: new AuthzClient(),
        accountsMgtSvcClient: new AccountsClient(
          AccountsClient.getBaseUrl(stack),
          authProviderService
        ),
        personClient: new PersonClient(
          PersonClient.getBaseUrl(stack),
          authProviderService
        ),
        userMgtClient: new UserMgtClient(
          UserMgtClient.getBaseUrl(stack),
          authProviderService
        ),
        grantClient: new GrantsClient(),
        userTenantDetailsClient: new UserTenantDetailsClient(
          UserTenantDetailsClient.getBaseUrl(stack),
          authProviderService.createOrglessAuthProvider()
        )
      },
      onecloud: {
        oauthClient: new OnecloudOauthClient({
          stack,
          authProvider: authProviderService
        }),
        logoutClient: new OnecloudLogoutClient({ stack, clientId }),
        registryRelationshipClient: new OnecloudRegistryRelationshipClient({
          stack,
          authProvider: authProviderService
        })
      }
    } as unknown as ClientsType;

    return clients;
  }
}
