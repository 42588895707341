import StageBuilder from '../../stageBuilder/StageBuilder';
import matchPath from '../../../../utils/matchPath';
import * as T from './types';
import { LOGIN_PATH_LIST } from '../../../../services/session/constants';

export default class LoginPathOperation implements T.RouterOperationInterface {
  private navigationInterface: T.LoginOperationDependenciesType['navigationInterface'];

  constructor(dependencies: T.LoginOperationDependenciesType) {
    this.navigationInterface = dependencies.navigationInterface;
  }

  // TODO: This is a specific scenario of PathOperation
  // TODO: This could be in a RouteService or something similar
  private isLoginPath(): boolean {
    let pathToCompare = this.navigationInterface?.location?.pathname || '';
    if (!pathToCompare.startsWith('/')) pathToCompare = '/' + pathToCompare;
    return matchPath(LOGIN_PATH_LIST, { exact: true, pathToCompare });
  }

  async process(stateBuilder: StageBuilder): Promise<void> {
    if (this.isLoginPath()) {
      stateBuilder.setContent({
        enable: false,
        isLogin: true
      });
      stateBuilder.setEndProcessChain(true);
    }
  }
}
