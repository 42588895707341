import EventNames from '../../../config/eventNames';
import { JWebErrorHandler, getJWebContainerInstanceId } from '../../JWeb';
import { SubscriptionHandle } from '../../JWeb/types';
import * as T from '../types';
import isObject from '../../../utils/isObject';
import IEventService from '../IEventService';
import { nativeEventPublisherPromise, nativeSubscriberPromise } from './utils';
import bindAllMethods from '../../../utils/bindAllMethods';
import { inject, singleton } from 'tsyringe';
import { publisherId } from '../utils';
import BaseEventService from '../BaseEventService';
import type { EventServiceInputType } from '../../../infra/commonInitializer/types';

@singleton()
class NativeEventService extends BaseEventService implements IEventService {
  public eventNames = EventNames;
  private eventsToInjectFilterByContainerInstanceId: Record<string, boolean> =
    {};

  constructor(
    @inject('EventServiceProps')
    eventServiceProps: EventServiceInputType
  ) {
    super();
    if (!eventServiceProps?.removeSelfFilterByContainerInstanceId) {
      Object.values(EventNames).forEach((eventName) => {
        this.eventsToInjectFilterByContainerInstanceId[eventName] = true;
      });
    }
    bindAllMethods(this);
  }

  async subscribe(
    eventName: string,
    action: T.EventServiceCallBackType,
    options?: T.EventServiceSubscribeOptionsType
  ): Promise<T.ListenerHandlerType> {
    const nativeSubscriber = await nativeSubscriberPromise;
    const jWebContainerInstanceId =
      (await getJWebContainerInstanceId()) || 'qwe';

    const nativeListenerHandler = JWebErrorHandler<SubscriptionHandle>(
      await nativeSubscriber?.subscribe(
        { eventName, publisherId: options?.publisherId },
        ({ eventData, publisherId: nativePublisherId }) => {
          const filterByContainerInstanceId = (eventData as any)
            ?.filterByContainerInstanceId;

          const allowedToCallMethod = (() => {
            if (typeof filterByContainerInstanceId === 'string') {
              return filterByContainerInstanceId === jWebContainerInstanceId;
            } else {
              return true;
            }
          })();

          if (allowedToCallMethod) {
            action({
              eventData,
              eventName,
              publisherId: nativePublisherId || publisherId
            });
          }
        }
      )
    );

    return {
      unsubscribe: async () => {
        await nativeListenerHandler.unsubscribe();
      }
    };
  }

  async publish(
    eventName: string,
    eventData: T.EventServiceValueType['eventData']
  ): Promise<void> {
    const sanitizedEventData = isObject(eventData)
      ? {
          ...eventData
        }
      : {};
    const nativeEventPublisher = await nativeEventPublisherPromise;

    if (
      this.eventsToInjectFilterByContainerInstanceId[eventName] &&
      sanitizedEventData.filterByContainerInstanceId === undefined
    ) {
      sanitizedEventData.filterByContainerInstanceId =
        (await getJWebContainerInstanceId()) || 'qwe';
    }

    await nativeEventPublisher?.publish(eventName, sanitizedEventData);
  }
}

export default NativeEventService;
