import { internalLogger } from '../../../../interface/v1/logger';
import { getServices } from '../../../../infra/commonInitializer';
import shouldGoToLoginPage from '../../operations/utils/shouldGoToLogin';
import AbstractOperation from '../AbstractOperation';
import { tenantSelectorScene } from './constants';
import * as T from './types';

type TenantSelectorOptionsType = {
  userOnboardingPath?: string;
};

export default class TenantSelectorOperation extends AbstractOperation {
  _userOnboardingPath: string;

  constructor(options?: TenantSelectorOptionsType) {
    super();
    this._userOnboardingPath = options?.userOnboardingPath;
  }

  public async process(stateBuilder: T.StageBuilder): Promise<void> {
    // Firsty, where does the user desire to go?

    // I don't trim the routes by the applyCriterionsMatch here, because the TenantSelector will replace it anyways.
    const route = await this.getRouteByCurrentPath(false);
    // To keep the retrocompatibly, the TenantSelector was only showed if the user have  requested a page WITH assetReference.
    if (!route?.assetReference) {
      return;
    }
    // If the next route is public, the pathRouteOperation will handle it.
    if (route?.public) {
      return;
    }

    //  We check if the page is private and the user is logged in.
    //  But we keeping the methods already used to check if the user is logged in.
    const userNeedsToLogin = await shouldGoToLoginPage({
      assetReference: route.assetReference,
      public: route.public
    });
    if (userNeedsToLogin) {
      // We redirect him to the login page.
      this.processToLogin(stateBuilder);
      return;
    }

    // Now, or the page is public, or is private and the user is already logged.

    // Moving forward, here we would made some "shallow" checkings, if the
    // tenantHandler is enabled, if the disired route is not public, and so on.
    const { tenantHandlerService } = getServices();
    const checkConditions =
      !tenantHandlerService.isEnabled() ||
      // isPathRoute &&
      this.isUserOnboardingPath();
    // DOUBT: Is isPathRoute already valid?

    if (checkConditions) {
      // The user not pass in some checking, our work here is over and a next operation will handle it.
      return;
    }

    // Now it our turn, the user MAY be need change its Tenant. Let's check that?
    const tenantData = tenantHandlerService.getNextUnproccessedTenant();

    if (tenantData?.strategy?.isRequiredToNavigate()) {
      // The TenantHandler informs us that the user needs to change the tenant.
      // We will load the Tenant Selector Page's content.
      this.processContent(stateBuilder, tenantSelectorScene);

      stateBuilder.setLayout({
        enable: true,
        assetReference:
          '@jarvis/react-shell-common-layout/tenantSelectorLayout.js'
      });
      // At this point, we must end the Process Chain.
      stateBuilder.setEndProcessChain(true);
    }

    // Everything is good, the user does not need to change the tenant.
    return;
  }

  isUserOnboardingPath(): boolean {
    const { navigationService } = getServices();
    const currentPath = navigationService.getHistory().location.pathname;
    if (this._userOnboardingPath && currentPath === this._userOnboardingPath) {
      /* istanbul ignore next */
      internalLogger?.debug?.('User is navigating in an onboarding path.');
      return true;
    }
    return false;
  }
}
