import { GrantControllerSvcClient } from '@jarvis/web-stratus-client/dist/src/clients/grants/grant-controller-svc';
import { GrantsResponseType } from '../../../services/grants/types';
import { GetGrantsPropsType } from './types';
import IGrantsClient from './IGrantsClient';

export default class GrantsClient implements IGrantsClient {
  async getGrants({
    stack,
    authProvider,
    offset = 0
  }: GetGrantsPropsType): Promise<GrantsResponseType> {
    const grantClient = new GrantControllerSvcClient(stack, authProvider);
    return (await grantClient.getGrants({ offset: offset }))
      ?.data as GrantsResponseType;
  }

  async getPendingGrants({
    stack,
    authProvider
  }: GetGrantsPropsType): Promise<GrantsResponseType> {
    const grantClient = new GrantControllerSvcClient(stack, authProvider);
    return (await grantClient.getPendingGrants())?.data as GrantsResponseType;
  }
}
