import { getServices } from '../../../infra/commonInitializer';
import {
  FallbackOperation,
  LoginPathOperation,
  OnboardingAgentOperation,
  PathRouteOperation,
  ServiceRoutingOperation,
  TenantSelectorOperation
} from '../operations';
import { ChainOfOperationsInputType, RouterOperationInterface } from '../types';

export const CreateStandardOperationFlow = (
  options: ChainOfOperationsInputType
): RouterOperationInterface[] => {
  // All theses services must be ready here.
  const {
    tenantHandlerService,
    sessionService,
    monitoringService,
    navigationService
  } = getServices();

  //Another wall of shame of interfaces
  const {
    storeInterface,
    navigationInterface,
    fallbackInterface,
    authProviderInterface,
    localizationInterface
  } = options;

  // The order matters
  return [
    new FallbackOperation({
      fallbackInterface
    }),
    new LoginPathOperation({
      navigationInterface
    }),

    new ServiceRoutingOperation(),
    new OnboardingAgentOperation({
      globalPreloadAssetReferenceLocale:
        options.data.localization?.preloadAssetReferenceLocale,
      resumeOnTriggers: !!options.data.onboarding?.resumeOnTriggers,
      authProviderInterface,
      navigationInterface,
      stack: options.data.stack,
      storeInterface,
      onboardingTriggerList: options.data.onboarding?.triggers,
      onboardingDirectorClientId: options.data.onboarding?.clientId,
      localizationInterface: localizationInterface,
      fallbackInterface,
      sessionService,
      tenantHandlerService,
      monitoringService,
      navigationService
    }),
    new TenantSelectorOperation({
      userOnboardingPath: options.data.onboarding?.userOnboardingPath
    }),
    new PathRouteOperation({
      // TODO: preloadAssetReferenceLocale should came from LocalizationService
      globalPreloadAssetReferenceLocale:
        options.data.localization?.preloadAssetReferenceLocale,
      fallbackInterface
    })
  ];
};
