import { CheckReturnObjectType } from '../../interface/v1/Criterion/types';
import StageBuilder from './stageBuilder/StageBuilder';
import * as T from './types';
import matchPath from '../../utils/matchPath';
import IMfeRouterService from './IMfeRouterService';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import SceneReporter from './sceneReporter/SceneReporter';
import * as OperationFactory from './daemons/OperationFactory';
import Criterion from '../../interface/v1/Criterion';
import bindAllMethods from '../../utils/bindAllMethods';
import { internalLogger } from '../../interface/v1/logger';
import EventNames from '../../config/eventNames';

export default class MicrofrontendRouterService implements IMfeRouterService {
  private criterionInterface: Criterion<any>;
  private fallbackInterface: T.ChainOfOperationsInputType['fallbackInterface'];
  private navigationInterface: T.ChainOfOperationsInputType['navigationInterface'];
  private storeInterface: T.ChainOfOperationsInputType['storeInterface'];
  private authProviderInterface: T.ChainOfOperationsInputType['authProviderInterface'];
  private localizationInterface: T.ChainOfOperationsInputType['localizationInterface'];

  public operations;
  public eventName = EventNames.globalMicrofrontendRouter;

  private _sceneReporter;
  private _tenantHandlerService;
  private _eventService;

  private started = false;
  private state: T.SceneType = {};
  private updateCurrentMicrofrontendPromiseId = 0;
  private setStatePromiseId = 0;

  constructor() {
    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    this._eventService = services.eventService;
    this._tenantHandlerService = services.tenantHandlerService;
  }

  public setInterfaceDependencies(
    options: T.MicrofrontendRouterDependenciesType
  ): void {
    // The wall of shame of interfaces
    this.storeInterface = options.interfaces.store;
    this.navigationInterface = options.interfaces.navigation;
    this.fallbackInterface = options.interfaces.fallback;
    this.criterionInterface = options.interfaces.criterion;
    this.authProviderInterface = options.interfaces.authProvider;
    this.localizationInterface = options.interfaces.localization;
  }

  public async init(data: T.OperationDataInputType): Promise<void> {
    // These init methods allow to async boostrapping the services.

    // Here, it needs the interfaces already working.
    // Composition Design Pattern.
    this._sceneReporter = new SceneReporter({
      fallbackInterface: this.fallbackInterface,
      criterionInterface: this.criterionInterface,
      storeInterface: this.storeInterface,
      navigationInterface: this.navigationInterface,
      eventService: this._eventService
    });

    const operations = OperationFactory.CreateStandardOperationFlow({
      data: data,
      storeInterface: this.storeInterface,
      navigationInterface: this.navigationInterface,
      fallbackInterface: this.fallbackInterface,
      authProviderInterface: this.authProviderInterface,
      localizationInterface: this.localizationInterface
    });
    this.operations = operations;
  }

  // TODO: From here down, everything could be reworked to use more Composition approach
  // TODO: This method exists only because onboarding agent is initialized after shell-commons
  // it should be removed on future when we have a centralized solution
  async start(): Promise<void> {
    if (!this.started && !!this._eventService) {
      const syncData = async () => {
        await this.updateState();
      };

      this.started = true;

      this._sceneReporter.setEventListeners(syncData);

      return syncData();
    }
  }

  public async checkCriterion(
    criterionKey?: string | false
  ): Promise<CheckReturnObjectType> {
    if (!criterionKey)
      return {
        result: true
      };

    const criterion =
      await this.criterionInterface.checkAdditionalCriterionDataByKey(
        criterionKey
      );

    return criterion;
  }

  private async setState(data: T.StageType) {
    internalLogger?.log?.(
      'resolving mfeRouterService.setState to ',
      JSON.stringify(data)
    );
    const thisPromiseId = this.setStatePromiseId + 1;
    this.setStatePromiseId = thisPromiseId;

    if (data?.redirectTo) {
      // TODO: this should be the navigationService
      const pathToCompare = this.navigationInterface.location.pathname;
      const isSamePath = matchPath(data?.redirectTo, {
        exact: true,
        pathToCompare
      });
      if (!isSamePath) {
        this.navigationInterface.push(data.redirectTo);
      }
    } else {
      const tenantHandlerOverride = data?.content?.tenantHandlerOverride;
      this._tenantHandlerService.setTenantHandlerKey(tenantHandlerOverride);

      const contentCriterion = await this.checkCriterion(
        data?.content?.criterionKey
      );

      if (!contentCriterion?.result) {
        this.fallbackInterface.setCurrentFallbackByKey(
          contentCriterion?.fallbackKey
        );
      }

      const getMicrofrontendObject = async (
        newMicrofrontendRouterAsset: T.MicrofrontendRouterAssetType,
        currentMicrofrontendRouterAsset: T.MicrofrontendRouterAssetType
      ) => {
        const criterion = await this.checkCriterion(
          newMicrofrontendRouterAsset?.criterionKey
        );

        if (criterion?.result) {
          // Keep old object reference if the new object have the same values
          const isDifferentValue =
            JSON.stringify(newMicrofrontendRouterAsset) !==
            JSON.stringify(currentMicrofrontendRouterAsset);

          if (isDifferentValue) {
            return newMicrofrontendRouterAsset;
          } else {
            return currentMicrofrontendRouterAsset;
          }
        }
      };

      const newState: T.SceneType = {
        content: await getMicrofrontendObject(
          data?.content,
          this.state?.content
        ),
        layout: await getMicrofrontendObject(data?.layout, this.state?.layout),
        modalContent: await getMicrofrontendObject(
          data?.modalContent,
          this.state?.modalContent
        )
      };

      const isLastPromise = thisPromiseId === this.setStatePromiseId;
      const isDifferentValue =
        JSON.stringify(newState) !== JSON.stringify(this.state);

      if (isLastPromise && isDifferentValue) {
        this.state = newState;
        this._eventService.publish(this.eventName, { data: this.state });
      }
    }
  }

  private async updateState() {
    const stateBuilder = new StageBuilder({});
    const thisPromiseId = this.updateCurrentMicrofrontendPromiseId + 1;
    this.updateCurrentMicrofrontendPromiseId = thisPromiseId;

    const recursiveOperationProcess = async (
      operations: T.RouterOperationInterface[],
      promiseId: number
    ) => {
      const [thisOperation, ...nextOperations] = operations;

      await thisOperation?.process?.(stateBuilder);
      const thisState = stateBuilder.getState();

      if (
        !thisState?.endProcessChain &&
        Array.isArray(nextOperations) &&
        nextOperations.length > 0 &&
        this.updateCurrentMicrofrontendPromiseId === promiseId
      ) {
        await recursiveOperationProcess(nextOperations, promiseId);
      }
    };

    await recursiveOperationProcess(this.operations, thisPromiseId);

    if (this.updateCurrentMicrofrontendPromiseId === thisPromiseId) {
      const newState = stateBuilder.getState();
      await this.setState(newState);
    }
  }

  public getState(): T.SceneType {
    return this.state;
  }

  /**
   * This method allows to subscribe to changes in the MFE router state by
   * providing a callback function. THe callback function receives the current
   * MFE state.
   * @param callback
   * @returns
   */
  public listen(callback: (state: T.SceneType) => void): () => void {
    const callbackProxy = async () => {
      callback?.(this.getState());
    };

    this._eventService?.addEventListener?.(this.eventName, callbackProxy);

    return () =>
      this._eventService?.removeEventListener?.(this.eventName, callbackProxy);
  }

  // TODO: This could be derived from "an implements of UseReactHookable" interface
  public useReactHook(React: any): T.SceneType {
    const [state, setState] = React.useState(this.getState());

    React.useEffect(() => {
      const removeListener = this.listen((value) => setState(value));

      return () => removeListener();
    }, []);

    return state;
  }
}
