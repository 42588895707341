enum EventNames {
  shellCriterionChangedEventName = 'shell-criterion-changed',
  shellEntitlementChangedEventName = 'shell-entitlement-changed',
  shellAccessControlChangedEventName = 'shell-access-control-changed',
  shellUserLogedOutEventName = 'shell-user-logged-out',
  shellUserLogedInEventName = 'shell-user-logged-in',
  shellCallInterfaceNavigationPush = 'shell-interface-v1-navigation-push',
  clientosCallInterfaceNavigationPush = 'clientos-northbound-api-v1-navigation-push',
  clientosCallInterfaceNavigationGoBack = 'clientos-northbound-api-v1-navigation-goBack',
  clientosNavigationBlocked = 'clientos-navigation-blocked',
  shellUrlChanged = 'shell-url-changed',
  shellFeatureFlagChangedEventName = 'shell-feature-flag-changed',
  webOnboardingStarted = 'WebOnboardingStarted',
  webOnboardingStageStarted = 'WebOnboardingStageStarted',
  webOnboardingStageFinished = 'WebOnboardingStageFinished',
  webOnboardingFinished = 'WebOnboardingFinished',
  shellAnalyticsSimpleUiEvent = 'shell-analytics-simpleui-event',
  shellTenantChangedEventName = 'shell-tenant-changed',
  shellGrantsChangedEventName = 'shell-grants-changed',
  shellPendingGrantsChangedEventName = 'shell-pending-grants-changed',
  shellCriterionForceCheckUpdate = 'shell-criterion-force-check-update',
  globalMicrofrontendRouter = 'shell-global-microfrontend-router',
  globalMicrofrontendRouterURL = 'shell-global-microfrontend-router-url',
  globalMicrofrontendRouterOnboarding = 'shell-global-microfrontend-router-onboarding',
  globalFallback = 'shell-fallback'
}

export default EventNames;
